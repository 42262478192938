<template>
    <div class="col-md-9">
        <div class="courses-title">
            <div class="d-flex flex-row-reverse pb-3">
                <button class="btn badge bg-primary end-0">
                    {{ question.sort }} of {{ totalQuestions }} Questions
                </button>
            </div>
            <div class="progress mb-4 text-center">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"  :style="{'width':(timer / duration ) * 100 + '%'}" :aria-valuenow="duration" aria-valuemin="0" aria-valuemax="100"></div>
                <small class="justify-content-center d-flex position-absolute w-50 text-center">
                    <span v-if="timer">{{ timer }} / {{ duration}} sec</span>
                    <span v-else>Time Out</span>
                </small>
            </div>

            <div class="text-start">
                <div class="shadow">
                    <h6>{{ question.title }}</h6>
                    <p>{{ question.description }}</p>
                </div>
                <div class="list-group shadow" v-if="timer">
                    <button type="button" class="list-group-item list-group-item-action" :class="{active : option.id == attempted.test_question_option_id }" v-for="option in question.test_question_options" :key="option.id" @click="attemptQuestion(option.id)">
                        {{ option.title }}
                    </button>
                </div>
                <div class="list-group shadow" v-else>
                    <button type="button" class="list-group-item list-group-item-action" :class="{active : option.id == attempted.test_question_option_id }" v-for="option in question.test_question_options" :key="option.id"  disabled>
                        {{ option.title }}
                    </button>
                </div>
            </div>
        </div>
        <nav aria-label="Page navigation example">
            <ul class="pagination pagination-lg justify-content-end">
                <li class="page-item">
                <router-link  class="btn btn-primary" :to="{ name: 'SelfTestsQuestion', params: {id: next}}" v-if="next && timer">Next</router-link>
                <router-link  class="btn btn-primary" :to="{ name: 'SelfTestsResults', params: {id: question.test_id}}" v-else>Results</router-link>
                </li>
            </ul>
        </nav>
    </div>              
</template>

<script>
import axios from 'axios'
export default {
    props: ['duration','question','next','totalQuestions'],
    data(){
        return {
            attempted: '',
            timer: this.duration,
            random_id: null,
        }
    },
    methods:{
        setTimeout(){
            if(this.timer > 0) {
                setTimeout(() => {
                    this.timer -= 1
                    this.setTimeout()
                }, 1000)
            }
        },
        generateRandomId(){
           this.random_id = Math.ceil(Math.random()*1000000) 
           localStorage.setItem('temp_id', this.random_id)
        },
        attemptQuestion(id){
            
            axios.post('https://apitraining.vipawaworks.com/api/self_test_question_option/take_option/' + id +'/' + this.random_id).then(response => {
               this.attempted = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
    },
    created(){
        this.setTimeout()
        this.generateRandomId()
    }
    
}
</script>

<style>
.progress span {
	position: absolute;
	display: block;
	width: 100%;
	color: #000;
}
</style>